import { useNavigation, type FetcherWithComponents } from '@remix-run/react';

export const useFetcherIsActionReload = <T>(
    fetcher: FetcherWithComponents<T>
): fetcher is FetcherWithComponents<T> & { data: NonNullable<T> } => {
    const navigation = useNavigation();
    return (
        fetcher.state === 'loading' &&
        fetcher.formMethod !== null &&
        navigation.formMethod !== 'GET' &&
        // If we returned data, we must be reloading
        fetcher.data !== null
    );
};
